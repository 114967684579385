<template>
  <div v-if="currentRole === 4 || currentRole === 5" id="course-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div class="d-flex justify-space-between">
            <h2 class="mb-10">Lesson Note</h2>
          </div>
          <div class="d-flex">
            <v-row>
              <v-col md="3" style="background-color: rgba(196, 196, 196, 0.25)">
                <v-text-field
                  outlined
                  dense
                  placeholder="Search class... "
                  hide-details
                  v-model="searchClass"
                  counter
                  maxlength="100"
                  style="background: white"
                ></v-text-field>
              </v-col>
              <v-col md="3" style="background-color: rgba(196, 196, 196, 0.25)">
                <v-autocomplete
                  chips
                  small-chips
                  deletable-chips
                  hide-details
                  outlined
                  dense
                  multiple
                  label="Search center"
                  :items="centers"
                  item-text="location"
                  item-value="locationID"
                  v-model="center"
                  style="background: white"
                  @change="selectLocation"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="2" style="background-color: rgba(196, 196, 196, 0.25)">
                <v-text-field
                  type="date"
                  label="From date"
                  style="background: white"
                  v-model="fromDate"
                  outlined
                  dense
                  hide-details
                  @change="selectFromDate"
                />
              </v-col>
              <v-col md="2" style="background-color: rgba(196, 196, 196, 0.25)">
                <v-text-field
                  type="date"
                  label="To date"
                  style="background: white"
                  v-model="toDate"
                  outlined
                  dense
                  hide-details
                  @change="selectToDate"
                />
              </v-col>
              <v-col md="2" style="display: grid; background-color: rgba(196, 196, 196, 0.25)">
                <v-btn
                  elevation="0"
                  height="36"
                  class="pl-5 pr-5"
                  color="primary"
                  @click="search()"
                  :disabled="!fromDate || !toDate"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-row class="mt-7 justify-end">
            <v-btn
              class="px-10"
              elevation="0"
              height="36"
              color="primary"
              @click="downloadExcel"
              :disabled="isLoading"
            >
              Download file excel
            </v-btn>
          </v-row>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>
          <v-data-table
            v-if="!isLoading"
            :headers="headers"
            :items="filtered"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-header
            hide-default-footer
            @page-count="pageCount = $event"
            class="elevation-0 pt-5"
            style="overflow-x: scroll"
            fixed-header
            height="55vh"
          >
            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    role="columnheader"
                    scope="col"
                    aria-label="No"
                    class="text-start font-weight-bold"
                    :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                  >
                    <span>{{ header.text }}</span>
                  </th>
                </tr>
              </thead>
              <tr class="fixed-row-filter">
                <th v-for="header in props.headers" :key="header.text">
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </th>
              </tr>
            </template>

            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  {{ itemsPerPage * (page - 1) + index + 1 }}
                </td>
                <td>
                  {{ item.location }}
                </td>
                <td>
                  <div @click="viewDetail(item.classID)" style="color: cornflowerblue">
                    <span class="link">{{ item.classCode }}</span>
                  </div>
                </td>
                <td>
                  <div @click="viewStaff(item.qc_userID)">
                    <span style="color: cornflowerblue" class="link">{{ item.qcName }}</span>
                  </div>
                </td>
                <td>
                  <div>Lesson {{ item.lessonNo }}</div>
                </td>
                <td>
                  {{ item.lessonDate | getDate }}
                </td>
                <td>
                  <div class="short-text">{{ item.lessonNote }}</div>
                </td>

                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="18"
                        @click="openEditNote(item)"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        >visibility
                      </v-icon>
                    </template>
                    <span>View note</span>
                  </v-tooltip>
                  <div v-show="overlay" :class="'h'">
                    <div class="card-i">
                      <v-card
                        :style="{ boxShadow: 'none' }"
                        light
                        :min-width="screen < 700 ? '500' : '600'"
                      >
                        <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
                          Lesson note
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-textarea
                          :key="index"
                          :v-model="noteText"
                          :value="noteText"
                          name="input-7-1"
                          :disabled="true"
                          outlined
                          dense
                          height="30vh"
                          class="abc mt-3 ml-5 mr-5"
                          id="abcd"
                        ></v-textarea>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <div :class="'mb-4'" class="btnNote">
                            <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            v-if="!isLoading"
            :headers="headers"
            :items="filtered"
            :items-per-page="10000000"
            hide-default-header
            hide-default-footer
            class="elevation-0 pt-5"
            id="exported-lesson-note-table"
            fixed-header
            height="0vh"
          >
            <template v-slot:header="{ props }">
              <thead class="v-data-table-header">
                <tr>
                  <th
                    v-for="header in props.headers.slice(0, -1)"
                    :key="header.text"
                    role="columnheader"
                    scope="col"
                    aria-label="No"
                    class="text-start font-weight-bold"
                    :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                  >
                    <span>{{ header.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ item.location }}
                </td>
                <td>{{ item.classCode }}</td>
                <td>{{ item.qcName }}</td>
                <td>Lesson {{ item.lessonNo }}</td>
                <td>
                  {{ item.lessonDate | getDate }}
                </td>
                <td>
                  {{ item.lessonNote }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <div v-if="!isLoading" color="#fff" class="w-100 pl-10 pr-10">
      <v-row>
        <v-col md="2">
          <v-select
            outlined
            hide-details
            dense
            :value="itemsPerPage"
            :items="[20, 50, 100, 200]"
            @change="itemsPerPage = parseInt($event, 10)"
            style="height: 32px"
          ></v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-end">
          <h3 v-if="filtered.length != 1">Total: {{ filtered.length }} classes</h3>
          <h3 v-else>Total: {{ filtered.length }} class</h3>
        </v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-pagination
            v-model="page"
            :length="pageCount"
            elevation="0"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="errorDialog" persistent width="350">
        <v-card class="mx-auto pa-10">
          <div class="text-center">
            <h1>Error!</h1>
            <p>{{ message.messages.UNKNOWN_ERROR }}</p>
          </div>
          <div class="text-center">
            <v-btn color="error" elevation="0" @click="back()" outlined> Back </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { class_managerment } from '@/api/class-management.js'
import { account_managerment } from '@/api/account-management.js'
import dayjs from 'dayjs'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import message from '@/components/Utils/message'
import ConfigDate from '@/mixins/configDate'
import XLSX from 'xlsx-js-style'

export default {
  components: { Autocomplete },
  mixins: [ConfigDate],
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      isLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      dialog: false,
      overlay: false,
      noteText: '',
      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Center',
          value: 'location',
          width: '150px',
          sortable: false,
        },
        {
          text: 'Class code',
          value: 'classCode',
          width: '130px',
          sortable: false,
        },
        {
          text: 'QC',
          value: 'qcName',
          align: 'center',
          width: '160px',
          sortable: false,
        },

        {
          text: 'Lesson',
          value: 'lessonNo',
          width: '110px',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'lessonDate',
          width: '120px',
          sortable: false,
        },
        { text: 'Note', value: 'lessonNote', width: '200px', sortable: false },
        { text: 'Action', value: 'action', width: '60px', sortable: false },
      ],
      classList: [],
      classIDList: [],
      classInfoList: [],
      scheduleList: [],
      programmes: [],
      programmeID: null,
      filters: {
        classCode: [],
        location: [],
        qcName: [],
        lessonDate: [],
        lessonNote: [],
        lessonNo: [],
      },
      currentCenter: JSON.parse(localStorage.getItem('currentCenter')),
      searchClass: '',
      fromDate: dayjs().format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
      centers: [],
      center: '',
      errorDialog: false,
      message: message,
    }
  },
  computed: {
    filtered() {
      return this.classList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'lessonNote'
              ? this.filters[f].includes('Yes')
                ? d[f].trim() !== '-'
                : this.filters[f].includes('No')
                ? d[f].trim() === '-'
                : true
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
  },
  created() {
    this.centers = [parseInt(localStorage.getItem('currentCenter'))]
    this.center = [parseInt(localStorage.getItem('currentCenter'))]

    if (localStorage.getItem('dailyClassCenterSearch')) {
      this.center = JSON.parse(localStorage.getItem('dailyClassCenterSearch'))
    }
    if (localStorage.getItem('dailyFromDateSearch')) {
      this.fromDate = dayjs(JSON.parse(localStorage.getItem('dailyFromDateSearch'))).format(
        'YYYY-MM-DD'
      )
    }
    if (localStorage.getItem('dailyToDateSearch')) {
      this.toDate = JSON.parse(localStorage.getItem('dailyToDateSearch'))
    }
    if (localStorage.getItem('filterClassManagement')) {
      this.filters = JSON.parse(localStorage.getItem('filterClassManagement'))
    }

    this.filters.lessonNote = ['Yes']
    this.init()
    this.getClassInfo()
  },
  methods: {
    downloadExcel() {
      if (this.filtered.length > 0) {
        const table_elt = document.getElementById('exported-lesson-note-table')
        const workbook = XLSX.utils.table_to_book(table_elt)

        // Access the first sheet in the workbook (assuming there's only one sheet)
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Define the column width for specific columns
        const columnWidths = [
          { wch: 3 },
          { wch: 15 },
          { wch: 18 },
          { wch: 30 },
          { wch: 15 },
          { wch: 10 },
          { wch: 40 },
        ]
        worksheet['!cols'] = columnWidths

        XLSX.writeFile(
          workbook,
          'Lesson note' +
            ' from ' +
            dayjs(this.fromDate).format('DD/MM/YYYY') +
            ' to ' +
            dayjs(this.toDate).format('DD/MM/YYYY') +
            '.xlsm'
        )
      }
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('filterClassManagement', JSON.stringify(this.filters))
    },
    selectLocation(value) {
      localStorage.setItem('dailyClassCenterSearch', JSON.stringify(value))
    },
    selectFromDate(value) {
      localStorage.setItem('dailyFromDateSearch', JSON.stringify(value))
    },
    selectToDate(value) {
      localStorage.setItem('dailyToDateSearch', JSON.stringify(value))
    },
    async init() {
      this.isLoading = true
      await account_managerment.getListCenter().then(res => {
        if (res) {
          this.centers = res
        }
      })

      const obj = {
        findKey: this.searchClass,
        locationIDs: this.center.length > 0 ? this.center : '',
        startDate: this.fromDate,
        endDate: this.toDate,
      }

      await class_managerment
        .getLessonNote(obj)
        .then(res => {
          if (res && !res.errors) {
            this.classList = []
            res.forEach(x => {
              this.classList.push(this.formatInfo(x))
            })
          } else {
            this.errorDialog = true
          }
        })
        .catch(() => {
          this.errorDialog = true
        })
      this.isLoading = false
    },
    formatInfo(lesson) {
      if (lesson.qcName == null) {
        lesson.qcName = '-'
      }
      if (lesson.lessonNote == '') {
        lesson.lessonNote = '-'
      }
      return {
        ...lesson,
        qcName: lesson.qcName,
      }
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    groupColumnValueList(val) {
      return val === 'lessonNote' ? ['Yes', 'No'] : this.classList.map(d => d[val]).filter(y => y)
    },
    toggle(value) {
      this.$nextTick(() => {
        if (
          this.filters[value].length ==
          this.classList
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, []).length
        ) {
          this.filters[value] = []
        } else {
          this.filters[value] = this.classList
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, [])
            .slice()
        }
      })
    },
    viewDetail(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID },
      })
    },
    viewStaff(userID) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userID,
        },
      })
    },
    editClass(classID) {
      this.$router.push({
        name: 'class-management-edit',
        params: { classID: classID },
      })
    },
    async search() {
      this.isLoading = true

      const obj = {
        findKey: this.searchClass,
        locationIDs: this.center.length > 0 ? this.center : '',
        startDate: this.fromDate,
        endDate: this.toDate,
      }

      await class_managerment
        .getLessonNote(obj)
        .then(res => {
          if (res && !res.errors) {
            this.classList = []
            res.forEach(x => {
              this.classList.push(this.formatInfo(x))
            })
          } else {
            this.errorDialog = true
          }
        })
        .catch(() => {
          this.errorDialog = true
        })
      this.isLoading = false
    },
    updateFromDate(event) {
      this.fromDate = event
    },
    updateToDate(event) {
      this.toDate = event
    },
    back() {
      window.history.back()
    },
    openEditNote(item) {
      this.overlay = true
      if (item.lessonNote == '-') {
        this.noteText = ''
      } else {
        this.noteText = item.lessonNote
      }
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #427df2;
  }
}
.short-text {
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}
.card-i {
  width: 500px;
  position: absolute;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
}
.btnNote {
  float: right;
  margin-top: 20px;
}
.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}

.v-application .pl-10 {
  margin-top: 0 !important;
}
</style>
